<template>
  <a :class="className" href="#" @click.prevent="generateCSV()"><flixIcon :id="'download'" /> {{ $t('message.csvDownload') }}</a>
</template>
<script>
import csv from './csv.js'
export default {
  components: {},
  props: {
    form: String,
    className: Object,
    filter: {
      type: [String, Date, Boolean],
      default () { return 'all' }
    },
    data: {
      type: [Array, Boolean],
      default () { return false }
    }
  },
  data () {
    return {
      date: new Date(),
      variables: {}
    }
  },
  mounted () {
    this.variables = this.$getUserVariables()
  },
  methods: {
    forceFileDownload (response) {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', this.$getNullsBefore(this.date.getFullYear()) + '-' + this.$getNullsBefore((this.date.getMonth() + 1)) + '-' + this.$getNullsBefore(this.date.getDate()) + '-' + this.$getNullsBefore(this.date.getHours()) + '-' + this.$getNullsBefore(this.date.getMinutes()) + '-' + this.$getNullsBefore(this.date.getSeconds()) + '-' + this.$flix_brand.toLowerCase() + '-' + this.variables.user.ID + '-' + this.form + '.csv')
      document.body.appendChild(link)
      link.click()
    },
    generateCSV () {
      var c = new csv()
      if (!this.data) {
        c.data = this.$store.getters.bookings[this.form]
      } else {
        c.data = this.data
      }
      c.filter = this.filter

      c.parent = this
      c.get()
      this.forceFileDownload(c.csv)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
